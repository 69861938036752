
import close_button from './../icons/close_button.svg'
import ekra_button from './button.vue'

export default {
  components: {
    close_button: close_button
    ekra_button: ekra_button
  }

  props: {
    title: {type: String, default: null}
    is_disabled_send: {type: Boolean, default: false}
  }

  data: () ->
    return {
      is_loaded: false
    }

  mounted: () ->
    setTimeout(() =>
      @is_loaded = true
    )
}
