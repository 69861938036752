
import ekra_button from './../layouts/button.vue'
import common_helpers from './../helpers/common.coffee'
import window_helpers from './../helpers/window.coffee'
import hotel_image from './../assets/hotel.jpg'
import ekra_facility from './../assets/ekra_facility.jpg'
import hotel_route from './../components/hotel_route.vue'
import confirmed_modal from './../components/confirmed_modal.vue'

export default {
  props: {
    items: {type: Object, required: true}
  }

  components: {
    ekra_button: ekra_button
    hotel_route: hotel_route
    confirmed_modal: confirmed_modal
  }

  data: () ->
    return {
      cache_info: {}
      is_show_hotel_route: false
      is_show_confirmed_modal: false
    }

  methods: {
    handler_create_hotel_query: () ->
      @is_show_confirmed_modal = true
      common_helpers.send_query({
        action: 'confirm_hotel'
        crypted: @$route.params.id
      })

    handler_open_on_hotel: (event) ->
      window.open(common_helpers.HOTEL_LINK)
      event.stopPropagation()
      event.preventDefault()

    calc_info: () ->
      if @$route.name == 'Invites'
        invite_id = @$route.params.id
        if invite_id not in @cache_info
          @cache_info[invite_id] = undefined
          try
            result = await common_helpers.send_query({
              action: 'get_invite_info'
              crypted: invite_id
            })
          catch
            result = undefined
            window_helpers.move_to_path([])
          @cache_info[invite_id] = result
      return
  }

  mounted: () ->
    @calc_info()

  computed: {
    direct_map_link: () ->
      return common_helpers.ROUTE_HOTEL_LINK

    current_info: () ->
      if @$route.name == 'Invites'
        return @cache_info[@$route.params.id]
      return

    hotel_image: () ->
      return 'url("' + hotel_image + '")'

    ekra_facility: () ->
      return 'url("' + ekra_facility + '")'

    help_link: () ->
      if not @course?
        return undefined
      mail = 'training@ekra.ru'
      title = @course.aliasName + ' - ' + @start_date
      message = 'Здравствуйте, у меня возник следующий ' + \
        'вопрос по вашей программе:'
      return 'mailto:' + mail + '?subject=' + title + '&body=' + message

    hotel_link: () ->
      return common_helpers.HOTEL_LINK

    course: () ->
      if @current_info?
        for item_name, item_info of @items
          if item_info.name == @current_info.course_name
            return item_info
        window_helpers.move_to_path([])
      return

    user_name: () ->
      if @current_info?
        return @current_info.person_name
      return '...'


    start_date: () ->
      if @current_info?
        return @current_info.start_date
      return '...'

  }

  watch: {
    '$route': {
      deep: true
      handler: () ->
        @calc_info()
    }
  }
}
