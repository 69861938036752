
import modal from './../layouts/modal.vue'
import helpers from './../helpers/common.coffee'
import form_sended from './../icons/form_sended.svg'

export default {
  props: {
    # name: {type: String, required: true}
    # current_value: {type: Object, required: true}
  }

  components: {
    modal: modal
    form_sended: form_sended
  }
}
