
export default {
  props: {
    label: {type: String, default: undefined}
    title: {type: String, default: undefined}
    active: {type: Boolean, default: false}
    is_disabled: {type: Boolean, default: false}
  }

  computed: {
    result_title: () ->
      if @title?
        return @title
      else
        return @label
  }
}
